@import url('https://fonts.googleapis.com/css?family=Lato');

body {
  background-color: #f2f2f2;
  font-family: 'Lato Regular', 'Lato';
  font-weight: 400;
  font-style: normal;
  color: #333333;
  min-height: 0;
  margin: 0;
}

h1 {
  font-family: 'Lato Regular', 'Lato';
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  margin: 32px;
}

.form-wrapper {
  background-color: #ffffff;
  width: 500px;
  margin: auto;
  padding: 18px 10px 10px 10px;
  margin-bottom: 50px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 2px rgba(204, 204, 204, 0.349019607843137);
}

.form-group {
  border: none;
}

.scroll-bar-compensator {
  /* https://css-tricks.com/elegant-fix-jumping-scrollbar-issue/ */
  margin-left: calc(100vw - 100%); 
}

fieldset {
  box-sizing: border-box;
  margin: 0;
}

.button-wrapper {
  padding: 10px;
  text-align: center;
}

label {
  display: block;
  margin: 0px 2px 10px 2px;
  font-size: 18px;
}

input[type=text],
input[type=email],
input[type=password],
input[type=date] {
  font-family: 'Lato Regular', 'Lato';
  width: 100%;
  height: 35px;
  font-size: 16px;
  box-sizing: border-box;
  padding: 2px 2px 2px 10px;
  border: 1px solid #e0e0e0;
}

input:required { /* To remove red outline on required inputs in Firefox */
  box-shadow: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #CCCCCC;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #CCCCCC;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #CCCCCC;
}

input[type=submit],
input[type=button] {
  font-family: 'Lato Regular', 'Lato';
  font-size: 18px;
  color: #ffffff;
  width: 250px;
  height: 50px;
  background-color: #169bd5;
  border: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  margin: 30px 0 40px 0;
  transition-property: background-color;
  transition-duration: 100ms;
  text-align: center;
  cursor:pointer;
}

input[type=submit]:hover,
input[type=button]:hover {
  background-color: #2895C4;
  cursor: pointer;
}

input[type=submit]:active {
  background-color: #138BBF;
}

.toast {
  font-family: 'Lato Regular', 'Lato' !important;
  text-align: center;
}

@media only screen and (max-width: 650px) {

  body {
    background: #fff;
  }

  .form-wrapper {
    width: 100%;
      border: none;
      box-shadow: none;
      padding: 0;
      margin-bottom: 0;
  }

  .button-wrapper {
    padding: 0 0.75em;
  }

  input[type=submit] {
    width: 100%;
  }
}