input[type=button] {
    width: 250px;
    height: 50px;
    margin: 0px;
    padding: 0px;
}

label {
    height: 50px;
    line-height: 50px;
    margin: 0px;
    padding: 0px;
}

.button-wrapper {
    padding: 0px;
}

.form-wrapper {
    padding: 18px 25px;
    margin-bottom: 12px;
    margin-top: 12px;
}

.flex-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-container.center {
    justify-content: center;
}

.date-container {
    padding-top: 20px;
}

.date-container.left {
    width: 49%;
}

.date-container.right {
    margin-left: 10px;
    width: 51%;
}