.custom-modal {
    width: 450px;
}


.custom-modal h2 {
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    margin: 20px 12px 12px 12px;
}

.custom-overlay {
    background: rgba(0, 0, 0, 0.50);
}

.custom-modal p {
    margin: 20px 12px;
    color: #666666;
    line-height: 24px;
}

.custom-modal button {
    color: red;
    cursor: pointer;
    outline: none;
}

.close-button-wrapper {
    display: flex;
    justify-content: space-around;
}

.btn.close,
.btn.primary,
.btn.secondary {
    width: 200px;
    margin-bottom: 20px;
    outline: none;
}

.btn.close {
    background: #F24236;
}

.btn.close:hover {
    background: #DC3C32;
}

.btn.secondary {
    background: #F2F2F2;
    color: #333333;
}

.btn.primary {
    background: #169BD5;
}

.btn.secondary:hover {
    background: #E0E0E0;
}

@media only screen and (max-width: 650px) {
    .custom-modal {
        width: 90%;
    }

    .btn.close,
    .btn.primary,
    .btn.secondary {
        width: 95%;
    }

    .btn.close,
    .btn.primary {
        margin-bottom: 0;
    }

    .close-button-wrapper {
        display: block;
        text-align: center;
    }
}