
.header-full {
    width: calc(100% - (100vw - 100%));
    font-size: 16px;
    padding: 12px 0px;
    padding-left: calc(100vw - 100%);
    background-color: #ffffff;
    border-bottom: 2px solid pink;
    display: block;
    overflow: hidden;
}

.header {
    max-width: 950px;
    margin: 0 auto;
}

.header-img {
    margin: 0 18px 0 0;
    width: 150px;
    vertical-align: middle;
    padding-bottom:10px;
}

.nav-links {
    display: inline;
    vertical-align: middle;
}

.header-links {
    color: #999999;
    border: none;
    box-shadow: none;
    font-size: 20px;
    font-weight: 500;
    padding: 0px 30px;
    text-decoration: none;
    line-height: 44px;
}

.header-links.selected {
    color: #169BD5;
}

.header-links.right {
    float: right;
    padding-right: 12px;
}

.header-links:hover {
    color: #169BD5;
}